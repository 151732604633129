<template>
  <b-card>
    <div slot="header">
      Graph <b-spinner small v-show="trendsLoading" label="Spinning"></b-spinner>
    </div>
    <line-chart :options="options" :chart-data="datacollection"></line-chart>
    <pre>{{ es_query }}</pre>
  </b-card>
</template>

<script>
  import RoyaltyApi from '@/mixins/RoyaltyApi'

  export default {
    mixins: [RoyaltyApi],
    components: {
      'LineChart': () => import('@/components/LineChart'),
    },
    data () {
      return {
        trendsLoading: false,
        es_query: '',
        datacollection: {},
        options: {
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day'
              }
            }]
          }
        }
      }
    },
    props: {
      'trendFilter': Object,
    },
    methods: {
      refreshTrends () {
        if (this.trendsLoading) return;
        this.trendsLoading = true
        this.streams = null
        this.transactions = null

        this.getTrendData(this.trendFilter, 'graph')
          .then(response => {
            var data = []
            this.es_query = response.es_query
            response.buckets.forEach((item) => {
              data.push({x: item.key_as_string, y: item.doc_count})
            })
            this.datacollection = {
              datasets: [
                {
                  data: data,
                }
              ]
            }
          }).finally(() => {
            this.trendsLoading = false
          })
      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }
    },
    watch: {
      trendFilter: {
        handler: 'refreshTrends', 
        deep: true
      }
    },
    mounted () {
      this.refreshTrends()
    },
  }
</script>

<style>

</style>