var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm._v(" Graph "),
          _c("b-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.trendsLoading,
                expression: "trendsLoading"
              }
            ],
            attrs: { small: "", label: "Spinning" }
          })
        ],
        1
      ),
      _c("line-chart", {
        attrs: { options: _vm.options, "chart-data": _vm.datacollection }
      }),
      _c("pre", [_vm._v(_vm._s(_vm.es_query))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }